import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Projects from "../components/Projects"
import Seo from "../components/SEO"
const ProjectsPage = ({
  data: {
    allDatoCmsProject: { nodes: projects },
  },
}) => {
  return (
    <Layout>
      <Seo title="Projects" description="Daniela's research projects" />
      <section className="projects-page">
        <Projects
          projects={projects}
          title="Recent Projects"
          showLink={false}
        />
      </section>
    </Layout>
  )
}

export const query = graphql`
  {
    allDatoCmsProject {
      nodes {
        id
        description
        url
        slug
        image {
          fluid {
            ...GatsbyDatoCmsFluid
          }
        }
        title
      }
    }
  }
`

export default ProjectsPage
